/**
 * 日付のフォーマット関数
 * @param {Date} date
 */
const dateFormat = (date) => {
  return `
${date.getFullYear()}-
${(date.getMonth() + 1).toString().padStart(2, "0")}-
${date.getDate().toString().padStart(2, "0")}_
${date.getHours().toString().padStart(2, "0")}:
${date.getMinutes().toString().padStart(2, "0")}:
${date.getSeconds().toString().padStart(2, "0")}.
${date.getMilliseconds().toString().padStart(3, "0")}
`.replace(/[\n\r]/g, "");
};

$(document).ready(() => {
  "use strict"; // Start of use strict
  (($) => {
    const search = location.search.substring(1);
    const param = search ? "&" + search : "";
    $(".js-href-tracking").on("click", (e) => {
      e.preventDefault();
      const target = document.getElementsByClassName("js-href-tracking")[0];
      const dataparam = target.dataset.param ? "&" + target.dataset.param : "";
      const dateString = dateFormat(new Date());
      // location.href = `${$(e.currentTarget).attr(
      //   "href"
      // )}?date=${dateString}${dataparam}${param}`;
      location.href = 'https://www.hanaravi.jp/line_clinic.html';
      return false;
    });
  })(jQuery); // End of use strict
});
